<template>
  <div class="login-page">
    <LoginLayout :showAgreen="true" @changeChecked="changeChecked">
      <template v-slot:form>
        <van-form @submit="onSubmit">
          <van-field
            v-model="eamil"
            name="邮箱"
            label="邮箱"
            placeholder="请输入邮箱"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="请输入登录密码"
            :rules="[{ required: true, message: '' }]"
          />
          <ul class="space-between align-center login-help">
            <li @click="$router.push('/login/resetpassword')">忘记密码</li>
            <li @click="serve">联系客服</li>
          </ul>
          <div>
            <van-button
              class="res-btn"
              round
              block
              type="info"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </template>
      <template v-slot:btm>
        <div class="center-center go-register">
          <p @click="$router.push('/login/register')">邮箱注册</p>
        </div>
      </template>
    </LoginLayout>
  </div>
</template>
<script>
import LoginLayout from "@/views/login/components/LoginLayout.vue";
// eslint-disable-next-line no-unused-vars
import auth from "@/plugins/auth";
import apiUser from "@/api/user";
export default {
  name: "RegisterView",
  components: {
    LoginLayout,
  },
  data() {
    return {
      checked: false,
      eamil: "",
      password: "",
    };
  },
  computed: {
    config() {
      return this.$store.state.user.config;
    },
  },
  methods: {
    serve() {
      window.open(this.config.kefu_url);
    },
    async onSubmit() {
      if (!this.checked) {
        this.$toast("请阅读并同意《服务条款》和《隐私政策》");
        return;
      }
      this.mixinsLoadShow();
      const [err, res] = await apiUser.userLogin({
        username: this.eamil,
        password: this.password,
      });

      if (err) return;
      this.$store.commit("user/setToken", {
        token: res.data.token,
        user: {},
      });
      await this.$store.dispatch("user/getInfo");
      this.mixinsLoadClose();
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
        return;
      }
      this.$router.push("/home");
    },
    changeChecked(checked) {
      this.checked = checked;
    },
  },
};
</script>
<style lang="less" scoped>
.go-register {
  margin-top: 60px;
}
.login-help {
  margin: 15px 0 30px;
  & > li:nth-child(2) {
    color: @color-primary;
  }
}
</style>
