<template>
  <div class="resetpass-page">
    <NavigationTop title="忘记密码" :styleTitle="{ color: '#333' }" />
    <van-form ref="form" @submit="onSubmit" class="reset-pass-form">
      <van-field
        v-model="form.username"
        name="邮箱"
        label="邮箱"
        placeholder="请输入邮箱"
        :rules="[
          { required: true, message: '' },
          { pattern: emailRegex, message: '填写正确的邮箱' },
        ]"
      >
        <p>sdklfh dsfh</p>
      </van-field>
      <van-field
        v-model="form.code"
        clearable
        label="验证码"
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '' }]"
      >
        <template #button>
          <van-button
            class="get-code"
            @click="getCode"
            size="small"
            native-type="button"
            >{{ start ? `${countdown}秒后` : "获取验证码" }}</van-button
          >
        </template>
      </van-field>
      <van-field
        v-model="form.password"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="请输入新密码"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.password1"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="再次输入新密码"
        :rules="[{ validator: checkPassword }]"
      />
      <div class="btn-wrap">
        <van-button class="res-btn" round block type="info" native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import apiUser from "@/api/user";
export default {
  name: "LoginView",
  components: {},
  data() {
    return {
      emailRegex: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, // 邮箱正则
      form: {
        username: "",
        password: "",
        code: "",
        password1: "",
      },
      countdown: 60,
      start: false,
    };
  },
  computed: {
    config() {
      return this.$store.state.user.config;
    },
  },
  methods: {
    serve() {
      window.open(this.config.kefu_url);
    },
    checkPassword() {
      if (this.form.password1 !== this.form.password) {
        this.$toast("两次密码输入不一致");
      }
    },
    sendCode() {
      apiUser.senderEmail({
        email: this.form.username,
        emailType: 1,
      });
    },
    getCode() {
      if (!this.emailRegex.test(this.form.username)) {
        this.$toast("请输入正确的邮箱格式");
        return;
      }
      if (!this.start) {
        this.sendCode();
      }
      if (this.start) {
        return;
      }
      this.start = true;
      this.timer = setInterval(() => {
        this.countdown--; // 倒计时减少1秒
        if (this.countdown === 0) {
          this.start = false;
          clearInterval(this.timer); // 清除定时器
          this.countdown = 60;
        }
      }, 1000);
    },
    validate() {
      this.$refs.form.validate
        .then((valid) => {
          console.log(valid);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onSubmit() {
      this.mixinsLoadShow();
      const [err] = await apiUser.editPwd(this.form);
      if (err) return;
      this.$toast("修改密码成功");
      this.$router.push("/login/SignIn");
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.resetpass-page {
  background: #f7f7f7;
  /deep/ .get-code::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 0;
    width: 1px;
    height: 12px;
    background: rgba(170, 184, 209, 0.4);
    opacity: 1;
  }

  /deep/ .get-code {
    position: relative;
    background: none;
    border: none;
    color: #fe8b29;
  }
}

.resetpass-page {
  padding: 0 15px 30px;

  /deep/ .reset-pass-form {
    padding-top: 10px;
    .van-cell {
      background: none;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }

    .van-cell__value {
      margin-top: 10px;
    }
    .van-field__body {
      height: 50px;
      padding: 0 14px;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .btn-wrap {
    margin: 30px 15px 0;
  }
}
</style>
