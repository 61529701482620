import axios from "axios";
import store from "@/store";
import to from "await-to-js";
import app from "@/main";
import qs from "qs";
const fixUrl = "/api";
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API}${fixUrl}`,
  timeout: 6000,
});
instance.interceptors.request.use(
  (config) => {
    const token = store.state.user.token;
    if (token) config.headers.token = token;
    if (config.formData) {
      // 将请求数据转换为 Form Data
      config.data = qs.stringify(config.data);
      // 设置请求头 Content-Type
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    if (config.base) {
      config.baseURL = `${config.base}${fixUrl}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);
const specialCode = [];
instance.interceptors.response.use(
  (res) => {
    const result = res.data || {};
    app.$toast.clear();
    const { code, msg } = result;
    if (code !== 200) {
      if (code === 403) {
        app.$store.commit("user/loginOut");
        app.$router.push({
          name: "Login",
          backUrl: app.$router.currentRoute.fullPath,
        });
      }
      if (!specialCode.includes(code)) {
        app.$toast.fail(msg);
      }
      return Promise.reject(result);
    }
    return result;
  },
  async (err) => {
    app.$toast.clear();
    return Promise.reject(err);
  }
);

// 2. 导出一个通过新axios实例调用接口的函数
export default ({
  url,
  method = "get",
  params,
  data,
  headers,
  formData = false,
  base = "",
}) => {
  const promise = instance({
    url,
    method,
    params,
    data,
    headers,
    formData,
    base,
  });
  return to(promise);
};
