<template>
  <div class="user-page">
    <p class="setting-head d-flex">
      <img
        class="no-grow no-shrink"
        @click="$router.push('/usercenter/setting')"
        src="@/assets/img/setting.png"
      />
    </p>
    <ul class="user-bar d-flex">
      <li class="user-pic no-grow">
        <div class="user-img">
          <VImage :src="user.pic" />
        </div>
        <p class="level center-center">
          <span class="txt">S</span>{{ user.level2 }}
        </p>
      </li>
      <li class="user-name space-around flex-column flex-1">
        <p>{{ user.name }}</p>
        <p
          class="align-center user-id"
          v-clipboard:copy="user.invite_code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          ID: {{ user.invite_code }} <i></i>
        </p>
      </li>
      <li class="center-center no-grow">
        <p
          class="go-singin center-center"
          @click="
            $router.push({
              name: 'UserCheckin',
            })
          "
        >
          签到
        </p>
      </li>
    </ul>
    <div class="user-card space-between flex-column">
      <ul class="points">
        <li>积分</li>
        <li>{{ user.integral_total }}</li>
      </ul>
      <ul class="points-btm space-between">
        <li class="flex-column space-around">
          <p>A积分</p>
          <p>{{ user.money }}</p>
        </li>
        <li class="flex-column space-around">
          <p>B积分</p>
          <p>{{ user.integral_b }}</p>
        </li>

        <li class="flex-column space-around">
          <p>C积分</p>
          <p>{{ user.integral_c }}</p>
        </li>
      </ul>
    </div>
    <div class="user-nav">
      <ul class="user-nav-item">
        <li
          class="align-center"
          v-for="(item, idx) in navs"
          @click="skip(item)"
          :key="idx"
        >
          <i class="icon-left"
            ><img class="d-block" width="24" :src="item.left"
          /></i>
          <p class="flex-1 text">{{ item.text }}</p>
          <i class="icon-right"
            ><img height="12" class="d-block" :src="item.right" alt=""
          /></i>
        </li>
      </ul>
    </div>
    <p class="login-out center-center" @click="loginOut">退出登录</p>
  </div>
</template>
<script>
import right from "@/assets/img/lefticon.png";
import auth from "@/plugins/auth";
export default {
  name: "UserView",
  data() {
    return {
      navs: [
        {
          left: require("@/assets/img/transfer.png"),
          right: right,
          text: "C积分划转",
          path: "/usercenter/transfer",
        },
        {
          left: require("@/assets/img/exchange.png"),
          right: right,
          text: "积分兑换",
          path: "/usercenter/exchange",
        },
        {
          left: require("@/assets/img/depositWithdrawal.png"),
          right: right,
          text: "充值提现",
          path: "/usercenter/depositWithdrawal",
        },
        {
          left: require("@/assets/img/use.png"),
          right: right,
          text: "我的团队",
          path: "/usercenter/team",
        },
        {
          left: require("@/assets/img/add.png"),
          right: right,
          text: "邀请好友",
          path: "/usercenter/code",
        },
        {
          left: require("@/assets/img/turnover.png"),
          right: right,
          text: "流水查询",
          path: "/usercenter/turnover",
        },
        {
          left: require("@/assets/img/device.png"),
          right: right,
          text: "设备管理",
          path: "/usercenter/device",
        },
        {
          left: require("@/assets/img/order.png"),
          right: right,
          text: "我的商品",
          path: "/usercenter/order",
        },
        {
          left: require("@/assets/img/chat.png"),
          right: right,
          text: "客服",
          path: "serve",
        },
        {
          left: require("@/assets/img/setting.png"),
          right: right,
          text: "设置",
          path: "/usercenter/setting",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    config() {
      return this.$store.state.user.config;
    },
  },
  methods: {
    skip(item) {
      const path = item.path;
      if (!path) return;
      if (path == "serve") {
        this.serve();
        return;
      }
      this.$router.push(item.path);
    },
    serve() {
      window.open(this.config.kefu_url);
    },
    loginOut() {
      auth.clearToken();
      this.$router.push("/login/SignIn");
    },
    onCopy(e) {
      this.$toast(`复制成功：${e.text}`);
    },
    onError() {
      this.$toast(`复制失败`);
    },
  },
  created() {
    this.$store.commit("CHANGE_SHOW_BAR", true);
  },
};
</script>
<style lang="less" scoped>
.user-page {
  padding: 0 15px 0;
  .setting-head {
    justify-content: flex-end;
    align-items: center;
    height: 49px;
    padding-bottom: 9px;
    img {
      height: 24px;
    }
  }
  .user-pic {
    position: relative;
    .user-img {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .level {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 28px;
      min-height: 28px;
      border-radius: 50%;
      color: #fff;
      align-items: flex-end;
      background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%),
        linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5)
        );
      .txt {
        font-size: 18px;
        font-style: italic;
        margin-right: 2px;
      }
    }
  }
  .user-name {
    padding: 0 8px 0 15px;
    & > p:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
    }
    & > p:nth-child(2) {
      color: #999;
    }
    .user-id > i {
      height: 20px;
      width: 20px;
      background: url("@/assets/img/copy.png") no-repeat center center;
      background-size: 100% 100%;
      margin-left: 4px;
    }
  }
  .go-singin {
    color: #fff;
    text-align: center;
    font-size: 16px;
    width: 94px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%);
  }
  .user-card {
    color: #fff;
    background: url("@/assets/img/img-bg.png") no-repeat center center;
    background-size: 100% 100%;
    height: 176px;
    margin-top: 30px;
    padding: 23px 0 0;

    .points {
      padding: 0 20px;
      & > li:nth-child(1) {
        font-size: 16px;
      }
      & > li:nth-child(2) {
        font-size: 28px;
        margin-top: 12px;
      }
    }
    .points-btm {
      height: 64px;
      padding: 8px;
      margin-bottom: 22px;
      p {
        text-align: center;
      }
      li {
        flex: 1;
      }
      li:nth-child(2) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -11px;
          width: 1px;
          height: 22px;
          background-color: #fff;
        }
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -11px;
          width: 1px;
          height: 22px;
          background-color: #fff;
        }
      }

      & > li > p:nth-child(1) {
        font-size: 12px;
      }
      & > li > p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .user-nav {
    //height: 220px;
    border-radius: 12px;
    background: #fff;
    padding: 0 15px;
    margin-top: 23px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    color: #333;
    .user-nav-item {
      & > li {
        height: 55px;
        border-bottom: 1px solid #d3d3d3;
      }
      & > li:last-child {
        border-bottom: none;
      }
      .text {
        padding: 0 8px;
        line-height: 1;
      }
    }
  }
  .login-out {
    color: #fff;
    margin-top: 20px;
    font-size: 15px;
    height: 42px;
    border-radius: 10px;
    background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%);
  }
}
</style>
