import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import auth from "@/plugins/auth";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import RentingView from "../views/Renting.vue";
import BankView from "../views/Bank.vue";
import Welfare from "../views/Welfare.vue";
import UserView from "../views/user/UserView.vue";
import LoginView from "../views/login/LoginView.vue";
import RegisterView from "../views/login/RegisterView.vue";
import ResetPassword from "../views/login/ResetPassword.vue";
// import ShopDetail from "../views/shop/Detail.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/renting",
    name: "Renting",
    component: RentingView,
  },
  {
    path: "/bank",
    name: "Bank",
    component: BankView,
  },
  {
    path: "/bank/:id",
    name: "BankItem",
    component: () => import("../views/BankItem.vue"),
  },
  {
    path: "/welfare",
    name: "Welfare",
    component: Welfare,
  },
  {
    path: "/welfare/list",
    name: "WelfareList",
    component: () => import("../views/WelfareList.vue"),
  },
  {
    path: "/renting/:id",
    name: "RentingDetail",
    component: () => import("../views/RentingDetail.vue"),
  },
  {
    path: "/pay/:id",
    name: "RentingPay",
    component: () => import("../views/RentingPay.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("../views/shop/List.vue"),
  },
  {
    path: "/shop/:id",
    name: "ShopDetail",
    component: () => import("../views/shop/Detail.vue"),
  },
  {
    path: "/usercenter",
    name: "User",
    component: UserView,
  },
  {
    path: "/usercenter/checkin",
    name: "UserCheckin",
    component: () => import("@/views/user/Checkin.vue"),
  },
  {
    path: "/usercenter/code",
    name: "UserCode",
    component: () => import("@/views/user/UserCode.vue"),
  },
  {
    path: "/usercenter/team",
    name: "TeamManage",
    component: () => import("@/views/user/TeamManage.vue"),
  },
  {
    path: "/usercenter/setting",
    name: "Setting",
    component: () => import("@/views/user/Setting.vue"),
  },
  {
    path: "/usercenter/password",
    name: "PassWord",
    component: () => import("@/views/user/PassWord.vue"),
  },
  {
    path: "/usercenter/payword",
    name: "PayWord",
    component: () => import("@/views/user/PayWord.vue"),
  },
  {
    path: "/usercenter/certification",
    name: "Certification",
    component: () => import("@/views/user/Certification.vue"),
  },
  {
    path: "/usercenter/nick",
    name: "Nick",
    component: () => import("@/views/user/Nick.vue"),
  },
  {
    path: "/usercenter/usdt",
    name: "USDT",
    component: () => import("@/views/user/USDT.vue"),
  },
  {
    path: "/usercenter/address",
    name: "Address",
    component: () => import("@/views/user/Address.vue"),
  },
  {
    path: "/usercenter/service",
    name: "Service",
    component: () => import("@/views/user/Service.vue"),
  },
  {
    path: "/usercenter/privacy",
    name: "Privacy",
    component: () => import("@/views/user/Privacy.vue"),
  },
  {
    path: "/usercenter/team/withdraw",
    name: "TeamWithdraw",
    component: () => import("@/views/user/team/Withdraw.vue"),
  },
  {
    path: "/usercenter/team/recharge",
    name: "TeamRecharge",
    component: () => import("@/views/user/team/Recharge.vue"),
  },
  {
    path: "/usercenter/team/team",
    name: "Team",
    component: () => import("@/views/user/team/Team.vue"),
  },
  {
    path: "/usercenter/team/first",
    name: "First",
    component: () => import("@/views/user/team/First.vue"),
  },
  {
    path: "/usercenter/team/direct",
    name: "Direct",
    component: () => import("@/views/user/team/Direct.vue"),
  },
  {
    path: "/usercenter/team/register",
    name: "TeamRegister",
    component: () => import("@/views/user/team/Register.vue"),
  },
  {
    path: "/usercenter/turnover",
    name: "Turnover",
    component: () => import("@/views/user/Turnover.vue"),
  },
  {
    path: "/usercenter/device",
    name: "Device",
    component: () => import("@/views/user/Device.vue"),
  },
  {
    path: "/usercenter/order",
    name: "Order",
    component: () => import("@/views/user/Order.vue"),
  },
  {
    path: "/usercenter/device/:id",
    name: "DeviceItem",
    component: () => import("@/views/user/DeviceItem.vue"),
  },
  {
    path: "/announcementDetail/:id",
    name: "AnnouncementDetail",
    component: () => import("@/views/AnnouncementDetail.vue"),
  },
  {
    path: "/usercenter/transfer",
    name: "TransferView",
    component: () => import("@/views/user/Transfer.vue"),
  },
  {
    path: "/usercenter/exchange",
    name: "ExchangeView",
    component: () => import("@/views/user/Exchange.vue"),
  },
  {
    path: "/usercenter/depositWithdrawal",
    name: "DepositWithdrawal",
    component: () => import("@/views/user/DepositWithdrawal.vue"),
  },
  {
    path: "/usercenter/deposit",
    name: "Deposit",
    component: () => import("@/views/user/Deposit.vue"),
  },
  {
    path: "/usercenter/withdrawal-order",
    name: "WithdrawalOrder",
    component: () => import("@/views/user/WithdrawalOrder.vue"),
  },
  {
    path: "/login/SignIn",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/login/register",
    name: "Register",
    component: RegisterView,
  },
  {
    path: "/login/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return savedPosition || { x: 0, y: 0 };
  // },
});
const goLogin = (to) => {
  router
    .push({
      name: "Login",
      query: {
        redirect: to.fullPath === "/" ? "/home" : to.fullPath,
      },
    })
    .catch(() => {});
};
router.beforeEach(async (to, from, next) => {
  store.commit("CHANGE_SHOW_BAR", false);
  console.log(to, from);
  if (to.path === "/") {
    next("/home");
    // return router.push("/home").catch(() => {});
  }
  if (
    to.path === "/login/SignIn" ||
    to.path === "/login/register" ||
    to.path === "/login/resetpassword"
  ) {
    next();
  } else {
    if (!auth.getToken()) {
      goLogin(to);
    } else {
      if (!store.state.user.user.name) {
        let [error] = await store.dispatch("user/getInfo");
        if (error) {
          auth.clearToken();
          // store.commit("user/loginOut");
          goLogin(to);
        } else {
          next();
        }
      } else {
        next();
      }
    }
  }

  // if (!auth.getToken() && (to.name !== "Login" || to.name !== "Register")) {
  //   goLogin(to);
  // } else {
  //   if (
  //     !store.state.user.user.name &&
  //     (to.name !== "Login" || to.name !== "Register")
  //   ) {
  //     let [error] = await store.dispatch("user/getInfo");
  //     if (error) {
  //       store.commit("user/loginOut");
  //       goLogin(to);
  //     } else {
  //       next();
  //     }
  //   } else {
  //     next();
  //   }
  // }
  // const noLogin = to.path.startsWith("/login");
  // if (auth.getToken()) {
  //   if (!store.state.user.user.name) {
  //     let [error] = await store.dispatch("user/getInfo");
  //     if (error) {
  //       store.commit("user/loginOut");
  //       goLogin(to);
  //     } else {
  //       console.log(111, to.path);
  //       next();
  //     }
  //   } else {
  //     next();
  //   }
  // } else {
  //   // goLogin(to);
  // }
});
export default router;
