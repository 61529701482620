<template>
  <van-image width="100%" height="100%" :fit="fit" :src="url" />
</template>

<script>
import img from "@/assets/img/nickname.png";
export default {
  name: "VImage",
  props: {
    src: {
      type: String,
      default: "",
    },
    fit: {
      type: String,
      default: "cover",
    },
  },
  computed: {
    url() {
      let link = "";
      if (!this.src) {
        link = img;
      } else if (this.src.includes("data:image")) {
        link = this.src;
      } else if (this.src.startsWith("/Public")) {
        link = `${this.VUE_APP_API}${this.src}`;
      }
      return link;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less"></style>
