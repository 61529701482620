<template>
  <div class="renting-page">
    <!--    <NavigationTop-->
    <!--      title="租赁"-->
    <!--      titleColor="#fff"-->
    <!--      leftColor="#fff"-->
    <!--      :styleTitle="{ color: '#fff' }"-->
    <!--    />-->
    <div class="rent-cont">
      <ul
        v-for="(item, idx) in imgs"
        :key="idx"
        class="rent-item"
        :style="{
          backgroundImage: `linear-gradient(97deg, ${color[idx][0]} 0%, ${color[idx][1]} 100%)`,
        }"
        @click="goDetail(item)"
      >
        <li class="ccenter-center no-grow flex-column">
          <p class="text">{{ item.name }}</p>
          <p class="btn center-center">更多</p>
          <img class="right" width="120" :src="imgsData[idx]" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import apiUer from "@/api/user";
export default {
  name: "RentingView",
  data() {
    return {
      imgsData: [
        require("@/assets/img/rent-1.png"),
        require("@/assets/img/rent-2.png"),
        require("@/assets/img/rent-3.png"),
        require("@/assets/img/rent-4.png"),
      ],
      color: [
        ["#FF3D00", "#FF974B"],
        ["#FFB300", "#FFD232"],
        ["#755BFF", "#AF89FF"],
        ["#FEAD00", "#FE8B29"],
      ],
      imgs: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    goDetail(item) {
      if (!this.user.name) {
        this.$toast("请先登录");
        this.$router.push({
          name: "Login",
          query: { redirect: this.$route.fullPath },
        });
        return;
      }
      this.$router.push({
        path: `/renting/${item.id}`,
        query: { title: item.name },
      });
      return;
      /*if (
        item.limit_level == -1 ||
        item.limit_level?.indexOf(this.user.level2) > -1
      ) {
        this.$router.push({
          path: `/renting/${item.id}`,
          query: { title: item.name },
        });
        return;
      }
      this.$toast(
        `会员等级S${item.limit_level?.substring(0, 1)}及以上用户才可进入哦！`
      );*/
    },
  },
  async created() {
    this.$store.commit("CHANGE_SHOW_BAR", true);
    const [err, res] = await apiUer.typeList();
    if (err) return;
    this.imgs = res.data.map((v, k) => {
      return {
        ...v,
        src: this.imgsData[k] ? this.imgsData[k] : this.imgsData[0],
      };
    });
  },
};
</script>
<style lang="less" scoped>
.renting-page {
  background: no-repeat center top url("@/assets/img/top-bg.png");
  background-size: 100% auto;
}
.rent-cont {
  padding: 80px 20px 0;
  color: #fff;
  .rent-item {
    height: 120px;
    padding: 30px 0 0 30px;
    background: no-repeat center center;
    //background-size: cover;
    background-size: 100% auto;
    margin-bottom: 30px;
    border-radius: 10px;
    position: relative;
  }
  .text {
    font-size: 20px;
    font-weight: 600;
  }
  .btn {
    width: 70px;
    height: 24px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }
  .right {
    position: absolute;
    right: 10px;
    top: 0;
  }
}
</style>
