import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "@/plugins/vant";
import globalComponents from "@/components/global";
import "@/assets/style/base.less";
import load from "@/mixins/loading";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;
Vue.use(vant);
Vue.use(VueClipboard);
Vue.use(globalComponents);
Vue.mixin(load);
Vue.prototype.VUE_APP_API = process.env.VUE_APP_API;
const appVue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export default appVue;
