export default {
  data() {
    return {
      loadingclassName: "unique-loading",
    };
  },
  methods: {
    mixinsLoadShow() {
      this.$toast.loading({
        duration: 0, // 设置为0表示 loading 不会自动消失
        forbidClick: true, // 阻止用户点击其他区域
        loadingType: "spinner",
        className: this.loadingclassName, // 设置一个唯一的类名作为标识符
      });
    },
    mixinsLoadClose() {
      this.$toast.clear(this.loadingclassName);
    },
  },
};
