<template>
  <div class="register-page">
    <LoginLayout title="欢迎注册">
      <template v-slot:form>
        <van-form ref="form" @submit="onSubmit">
          <van-field
            v-model="form.username"
            name="邮箱"
            label="邮箱"
            placeholder="请输入邮箱"
            :rules="[
              { required: true, message: '' },
              { pattern: emailRegex, message: '填写正确的邮箱' },
            ]"
          />
          <van-field
            v-model="form.code"
            clearable
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '' }]"
          >
            <template #button>
              <van-button
                class="get-code"
                @click="getCode"
                size="small"
                native-type="button"
                >{{ start ? `${countdown}秒后` : "获取验证码" }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="form.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="请输入登录密码"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="form.parent"
            name="邀请码"
            label="邀请码"
            placeholder="请输入邀请码"
          />
          <div>
            <van-button
              class="res-btn"
              round
              block
              type="info"
              native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </template>
    </LoginLayout>
  </div>
</template>
<script>
import LoginLayout from "@/views/login/components/LoginLayout.vue";
import apiUser from "@/api/user";
export default {
  name: "LoginView",
  components: {
    LoginLayout,
  },
  data() {
    return {
      emailRegex: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, // 邮箱正则
      form: {
        username: "",
        password: "",
        code: "",
        parent: "",
      },
      countdown: 60,
      start: false,
    };
  },
  computed: {
    config() {
      return this.$store.state.user.config;
    },
  },
  created() {
    const parent = this.$route.query.parent;
    if (parent) {
      this.form.parent = parent;
    }
  },
  methods: {
    serve() {
      window.open(this.config.kefu_url);
    },
    sendCode() {
      apiUser.senderEmail({
        email: this.form.username,
        emailType: 0,
      });
    },
    getCode() {
      if (!this.emailRegex.test(this.form.username)) {
        this.$toast("请输入正确的邮箱格式");
        return;
      }
      if (!this.start) {
        this.sendCode();
      }
      if (this.start) {
        return;
      }
      this.start = true;
      this.timer = setInterval(() => {
        this.countdown--; // 倒计时减少1秒
        if (this.countdown === 0) {
          this.start = false;
          clearInterval(this.timer); // 清除定时器
          this.countdown = 60;
        }
      }, 1000);
    },
    validate() {
      this.$refs.form.validate
        .then((valid) => {
          console.log(valid);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onSubmit() {
      this.mixinsLoadShow();
      const [err] = await apiUser.register(this.form);
      if (err) return;
      this.$toast("注册成功");
      this.$router.push("/login/SignIn");
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.register-page {
  /deep/ .get-code {
    background: none;
    border: none;
    color: #333;
  }
}
</style>
