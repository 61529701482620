<template>
  <div class="bank">
    <div class="header">
      <img width="24" src="@/assets/img/icon_income.png" />
      <img width="89" src="@/assets/img/bank.png" />
    </div>
    <div class="swipe">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#FE8B29">
        <van-swipe-item class="item"></van-swipe-item>
        <van-swipe-item class="item"></van-swipe-item>
      </van-swipe>
    </div>
    <div class="product">
      <div class="product-header">
        <i></i><img width="90" src="@/assets/img/bank-1.png" /><i></i>
      </div>
      <ul class="product-content">
        <li v-for="item in info.product" :key="item.id">
          <div class="left">
            <img width="44" src="@/assets/img/integral_icon01.png" alt="" />
            <div>
              <div class="title">{{ item.name }}</div>
              <div class="info">
                到期可获得
                <span>{{ item.earnings }}积分</span>
              </div>
            </div>
          </div>
          <div @click="$router.push('/bank/' + item.id)" class="right">
            去体验
          </div>
        </li>
      </ul>
    </div>
    <div class="line">
      <div class="line-header">
        <div class="left">
          C积分
          <span style="color: #333; font-size: 12px; margin-left: 4px">
            {{ info.priceLog[0]?.date }}
          </span>
        </div>
        <div class="right">{{ info.toDayPrice }} <i></i></div>
      </div>
      <div class="chart"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import apiUser from "@/api/user";

echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);
export default {
  name: "BankView",
  data: () => {
    return {
      info: {
        distributionSum: "",
        priceLog: [],
        product: [],
      },
    };
  },
  async created() {
    this.$store.commit("CHANGE_SHOW_BAR", true);
  },
  async mounted() {
    const [err, res] = await apiUser.getBankInfo();
    if (err) return;
    this.info = res.data;
    const xData = [];
    const seriesData = [];
    res.data.priceLog.map((e) => {
      const tmp = e.date.split("-");
      xData.push(tmp[1] + "-" + tmp[2]);
      seriesData.push(e.price * 1);
    });
    console.log(xData, seriesData);
    window.localStorage.setItem("product", JSON.stringify(res.data.product));
    const myChart = echarts.init(document.querySelector(".chart"));
    let option;

    option = {
      grid: {
        left: "6%",
        right: "0%",
        top: "5%",
        bottom: "0%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xData.reverse(),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        // min: 10,
        type: "value",
        // data: [3294, 3312, 3323, 3888],
        position: "right",
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      series: [
        {
          data: seriesData.reverse(),
          type: "line",
          lineStyle: {
            color: "#FE8B29", // 指定线条颜色
          },
          markLine: {
            silent: true, // 这会使得标记线不响应鼠标事件
            data: [
              {
                yAxis: 3400, // 替换为您想要的y轴数值
                name: "黄色虚线",
                lineStyle: {
                  color: "yellow",
                  type: "dashed",
                },
              },
            ],
          },
          symbol: "none",
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(254, 139, 41, 0.20)",
              },
              {
                offset: 1,
                color: "rgba(254, 139, 41, 0.00)",
              },
            ]),
          },
        },
      ],
    };

    option && myChart.setOption(option);
  },
  methods: {},
};
</script>

<style scoped lang="less">
.bank {
  background: url("@/assets/img/integral_bg.png") no-repeat top center;
  background-size: 100% auto;
  padding: 0 16px;
  .product {
    margin-top: 30px;
    .product-content {
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      padding: 16px;
      margin-top: 11px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        padding: 16px 0;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        .left {
          display: flex;
          align-items: center;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: 500;
          }
          .info {
            color: #999;
            font-size: 12px;
            font-weight: 400;
            span {
              color: #fe8b29;
            }
          }
          img {
            margin-right: 8px;
          }
        }
        .right {
          width: 79px;
          height: 23px;
          border-radius: 11.5px;
          background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%);
          color: #fff;
          text-align: center;
          line-height: 23px;
          font-size: 12px;
        }
      }
    }
    .product-header {
      display: flex;
      justify-content: center;
      i {
        width: 13px;
        height: 16px;
        display: block;
        background: url("@/assets/img/bank-2.png") no-repeat center;
        background-size: 100%;
      }
      img {
        margin: 0 10px;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding: 20px 0 10px 0;
  }
  .swipe {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    .item {
      background: url("@/assets/img/banner-1.png") no-repeat center;
      background-size: 100%;
      height: 160px;
      &:last-child {
        background: url("@/assets/img/banner.png") no-repeat center;
        background-size: 100%;
        height: 160px;
      }
    }
  }
  .chart {
    height: 100px;
    margin-top: 16px;
  }
  .line {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 15px;
    margin: 20px 0;
    .line-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #ff3d00;
      }
      i {
        margin-left: 4px;
        background: url("@/assets/img/icon_rise.png") no-repeat center;
        background-size: 100%;
        display: flex;
        width: 17px;
        height: 17px;
      }
    }
  }
}
</style>
