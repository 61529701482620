<template>
  <div class="welfare">
    <div class="welfare-content">
      <div class="turntable">
        <div :style="{ transform: `rotate(${deg}deg)` }" class="table">
          <ul class="prize">
            <li
              :style="{ transform: `rotate(${45 * index + 22}deg)` }"
              v-for="(item, index) in prize"
              :key="item.title + index"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div @click="onSubmit" class="pointer"></div>
      </div>
      <div @click="onSubmit" class="btn">
        <i></i>
      </div>
    </div>
    <div class="container">
      <div class="rule">
        <div class="header">·参与规则·</div>
        <div class="body">
          <p><span>•</span> 每次抽奖需耗费一个C积分</p>
          <p><span>•</span> 奖品邮费需自行支付</p>
        </div>
      </div>
      <div class="count">
        <!--        <div>抽奖次数： <span>0</span></div>-->
        <div @click="$router.push('/welfare/list')">查看我的奖品</div>
      </div>
      <div class="display">
        <div class="header">·奖品展示·</div>
        <ul class="body">
          <li v-for="item in prize2" :key="item.title + 'key'">
            <!--            <VImage height="114" :src="item.img" />-->
            <img height="114" :src="item.img" />
            <span>{{ item.title }}</span>
            <div v-if="item.name.trim() !== '谢谢惠顾'">{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <van-dialog
      className="dialog-win"
      v-model="showWin"
      title=""
      :showConfirmButton="false"
    >
      <div class="win">
        <div class="title">恭喜您中奖啦！</div>
        <p>恭喜您在幸运大抽奖中获得{{ win.title }}</p>
        <p>奖品可在“我的奖品”中查看</p>
        <div @click="onAccept" class="btn">开心收下</div>
      </div>
    </van-dialog>
    <van-dialog
      className="dialog-win"
      v-model="showNoWin"
      title=""
      :showConfirmButton="false"
    >
      <div class="no-win">
        <div class="title">谢谢参与</div>
        <p>距离大奖只差一步之遥！</p>
        <div @click="showNoWin = false" class="btn">再来一次</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import apiUser from "@/api/user";
export default {
  name: "WelfareView",
  data: () => {
    return {
      showWin: false,
      showNoWin: false,
      isLoading: false,
      win: "",
      list: [
        {
          title: "戴森吸尘器V10 Fluffy",
          img: require("@/assets/img/welfare/1.png"),
        },
        { title: "iPad Air 256G", img: require("@/assets/img/welfare/2.png") },
        { title: "大疆无人机", img: require("@/assets/img/welfare/3.png") },
        { title: "AirPods Pro", img: require("@/assets/img/welfare/4.png") },
        { title: "LAMER面霜", img: require("@/assets/img/welfare/5.png") },
        { title: "LAMER精华油", img: require("@/assets/img/welfare/6.png") },
      ],
      deg: 0,
      prize: [],
      prize2: [],
    };
  },
  methods: {
    onAccept() {
      this.showWin = false;
      // window.scrollTo(0, 800);
      this.receive(this.win.rid);
    },
    async receive(id) {
      const [err, res] = await apiUser.receive({ id });
      if (err) return;
      this.$toast.success(res.msg);
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async onSubmit() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.timer = setInterval(() => {
        this.deg += 20;
      }, 25);
      const [err, res] = await apiUser.roll();
      if (err || res.code == 0) {
        clearInterval(this.timer);
        this.deg = 0;
        this.isLoading = false;
        return;
      }
      const id = res.data.id;
      this.prize.map((e, i) => {
        if (e.id === id) {
          clearInterval(this.timer);
          this.deg = 360;
          // this.getRandomInt(-20, 20);
          this.deg = 360 - (45 * i + 22.5) + this.getRandomInt(-20, 20);
          this.isLoading = false;
          if (res.data.title === "谢谢惠顾") {
            this.showNoWin = true;
            setTimeout(() => {
              this.showNoWin = false;
            }, 2000);
          } else {
            this.win = res.data;
            this.showWin = true;
            // setTimeout(() => {
            //   this.showWin = false;
            // }, 2000);
          }
        }
      });
    },
  },
  created() {
    this.$store.commit("CHANGE_SHOW_BAR", true);
    apiUser.prize().then(([err, res]) => {
      if (err) return;
      res.data.prize.map((_, i) => {
        res.data.prize[i].img = this.VUE_APP_API + res.data.prize[i].img;
      });
      this.prize = res.data.prize;
    });
    apiUser.showPrize().then(([err, res]) => {
      if (err) return;
      res.data.prize.map((_, i) => {
        res.data.prize[i].img = this.VUE_APP_API + res.data.prize[i].img;
      });
      this.prize2 = res.data.prize;
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="less">
.welfare {
  .dialog-win {
    background-color: rgba(0, 0, 0, 0);
    width: auto;
    .title {
      text-align: center;
      color: #333;
      font-size: 20px;
      margin-bottom: 10px;
    }
    p {
      color: #999;
      text-align: center;
      font-size: 14px;
    }
    .btn {
      color: #fff;
      text-align: center;
      font-size: 16px;
      border-radius: 22px;
      width: 200px;
      height: 44px;
      line-height: 44px;
      background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%);
      margin: 20px 0;
    }
  }
  .win,
  .no-win {
    width: 268px;
    height: 395px;
    background: url("@/assets/img/window_bg-2.png") no-repeat center;
    background-size: 100%;
    padding-top: 233px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .no-win {
    background: url("@/assets/img/window_bg-1.png") no-repeat center;
    background-size: 100%;
  }
  .container {
    padding: 0 13px 10px 13px;
    background: linear-gradient(180deg, rgb(235, 173, 129) 0%, #ffe0a9 100%);
  }
  .rule {
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .header {
      width: 132px;
      height: 30px;
      line-height: 30px;
      border-radius: 8px;
      background: linear-gradient(90deg, #fade8a 0%, #ffc55a 100%);
      box-shadow: 0px 2px 4px 0px rgba(198, 36, 0, 0.4);
      color: #a24001;
      text-align: center;
      font-size: 16px;
      z-index: 9;
    }
    .body {
      position: absolute;
      border-radius: 12px;
      border: 4px solid #fe8b29;
      background: #ffeac5;
      padding: 31px 0;
      width: 100%;
      top: 15px;
      p {
        text-align: center;
        span {
          font-weight: bolder;
        }
      }
    }
  }
  .display {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      width: 132px;
      height: 30px;
      line-height: 30px;
      border-radius: 8px;
      background: linear-gradient(90deg, #fade8a 0%, #ffc55a 100%);
      box-shadow: 0px 2px 4px 0px rgba(198, 36, 0, 0.4);
      color: #a24001;
      text-align: center;
      font-size: 16px;
    }
    .body {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 166px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 4px 15px 4px;
        //&:nth-child(2n) {
        //  margin-left: 13px;
        //}
        span {
          color: #383b43;
          font-size: 14px;
          text-align: center;
          margin-top: 16px;
        }
        div {
          margin-top: 12px;
          width: 108px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #fff;
          border-radius: 12px;
          background: linear-gradient(90deg, #fead00 0%, #fe8b29 100%);
        }
      }
    }
  }
  .count {
    display: flex;
    height: 60px;
    padding: 20px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #ffeac5;
    div:first-child {
      span {
        color: #fe8b29;
        font-size: 20px;
      }
    }
    div:last-child {
      font-size: 14px;
      color: #a24001;
      width: 114px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 8px;
      background: linear-gradient(90deg, #fade8a 0%, #ffc55a 100%);
    }
  }
}
.welfare-content {
  background: url("@/assets/img/raffle_bg.jpg") no-repeat top center;
  background-size: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13vw;
    margin: auto;
    width: 70vw;
    height: 14.4vw;
    background: url("@/assets/img/welfare-btn-bg.png") no-repeat center;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      display: block;
      width: 33.06vw;
      height: 10.01vw;
      background: url("@/assets/img/welfare-btn-text.png") no-repeat center;
      background-size: 100%;
    }
  }
  .turntable {
    position: absolute;
    width: 85vw;
    height: 85vw;
    left: 0;
    right: 0;
    margin: auto;
    top: 46vw;
    .table {
      background: url("@/assets/img/raffle_img1.png") no-repeat center;
      background-size: 100%;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      animation: all linear;
      .prize {
        width: 100%;
        height: 100%;
        position: relative;
        transform-origin: 50% 50%;
        //transform: rotate(24deg);
        li {
          transform-origin: 50% 100%;
          //transform: rotate(22deg);
          height: 50%;
          //background-color: #1ba27a;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          padding-top: 12.85%;
          margin: auto;
          text-align: center;
          color: #a24001;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .pointer {
      background: url("@/assets/img/raffle_img2.png") no-repeat center;
      background-size: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 42.6666vw;
      height: 42.6666vw;
    }
  }
}
</style>
