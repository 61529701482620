<template>
  <div id="app">
    <router-view :key="$route.path" />
    <div class="bottom-box" v-if="showBar">
      <ul class="bottom space-around">
        <li
          class="flex-column center-center bottom-cell"
          v-for="(item, idx) in imgs"
          @click="gopage(item)"
          :key="idx"
        >
          <span
            ><img
              :src="$route.path === item.path ? item.icon : item.active"
            /> </span
          ><span>{{ item.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import apiUser from "@/api/user";
import { mapState } from "vuex";
export default {
  name: "App",
  data() {
    return {
      imgs: [
        {
          icon: require("@/assets/img/menu/menu1.png"),
          active: require("@/assets/img/menu/menu1a.png"),
          text: "首页",
          path: "/home",
        },
        {
          icon: require("@/assets/img/menu/menu2.png"),
          active: require("@/assets/img/menu/menu2a.png"),
          text: "租赁",
          path: "/renting",
        },
        {
          icon: require("@/assets/img/menu/menu3.png"),
          active: require("@/assets/img/menu/menu3a.png"),
          path: "/welfare",
          text: "福利",
        },
        {
          icon: require("@/assets/img/menu/menu4.png"),
          active: require("@/assets/img/menu/menu4a.png"),
          path: "/bank",
          text: "积分银行",
        },
        {
          icon: require("@/assets/img/menu/menu5.png"),
          active: require("@/assets/img/menu/menu5a.png"),
          path: "/usercenter",
          text: "我的",
        },
      ],
    };
  },
  computed: {
    ...mapState(["showBar"]),
  },
  methods: {
    gopage(item) {
      if (item.path) {
        this.$router.push(item.path).catch(() => {});
        return;
      }
      this.$toast.success("待设计稿更新...");
    },
  },
  async created() {
    const [err, res] = await apiUser.getConfig();
    if (err) return;
    this.$store.commit("user/SetConfig", res.data);
  },
};
</script>
<style lang="less" scoped>
.bottom-box {
  &,
  .bottom {
    z-index: 100;
    height: 50px;
  }
  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #d3d3d3;
    line-height: 1;
    img {
      height: 30px;
      display: block;
    }
  }
}
</style>
