<template>
  <div class="navigation-box">
    <div
      :class="{ 'bg-White': bgWhite }"
      class="navigation space-between align-center"
    >
      <div class="icon-back center-center" @click="goBack">
        <van-icon
          v-show="showLeft"
          class="left-icon"
          name="arrow-left"
          :color="leftColor"
          :size="leftSise"
        />
      </div>
      <div class="cont center-center" :style="styleTitle">
        <p class="ellipsis">{{ title }}</p>
      </div>
      <div class="icon-rit">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationTop",
  props: {
    bgWhite: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showLeft: {
      type: Boolean,
      default: true,
    },
    leftColor: {
      type: String,
      default: "#333",
    },
    leftSise: {
      type: String,
      default: "18",
    },
    styleTitle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    goBack() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push("/home");
    },
  },
};
</script>

<style scoped lang="less">
.navigation-box {
  background-color: rgba(255, 255, 255, 0);
  &,
  .navigation {
    z-index: 1000 !important;
    height: 50px;
    overflow: hidden;
    //background-color: #f7f7f7;
  }
  .navigation {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
  .bg-White {
    background-color: #fff;
  }
  .cont {
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    font-weight: 600;
  }
  .icon-back,
  .icon-rit {
    flex: 40px 0 0;
  }
  .left-icon {
    font-weight: bold;
  }
}
</style>
