import request from "@/plugins/request";
const site = process.env.VUE_APP_API1;
export default {
  userLogin(data) {
    return request({
      url: "/login/index",
      method: "post",
      formData: true,
      data,
    });
  },
  userInfo() {
    return request({
      url: "/user/getUserInfo",
      base: site,
    });
  },
  userOther() {
    return request({
      url: "/index/user_other",
      method: "post",
    });
  },
  typeList() {
    return request({
      url: "/robot/type_list",
    });
  },
  pcontent(params) {
    return request({
      url: "/robot/pcontent",
      params,
    });
  },
  teamDetail(params) {
    return request({
      url: "/agent/teamdetail",
      params,
    });
  },
  detail(params) {
    return request({
      url: "/robot/detail",
      params,
    });
  },
  buy(data) {
    return request({
      url: "/robot/buy",
      method: "post",
      formData: true,
      data,
    });
  },
  checkindesc() {
    return request({
      url: "/checkin/checkindesc",
      method: "post",
    });
  },
  checkin() {
    return request({
      url: "/checkin/checkin",
      method: "post",
    });
  },
  register(data) {
    return request({
      url: "/user/register",
      method: "post",
      data,
      base: site,
    });
  },
  editPwd(data) {
    return request({
      url: "/user/editPwd",
      method: "post",
      data,
      base: site,
    });
  },
  senderEmail(params) {
    return request({
      url: "/user/senderEmail",
      params,
      base: site,
    });
  },
  getConfig() {
    return request({
      url: "/index/cconfig",
    });
  },
  banner() {
    return request({
      url: "/index/banner",
    });
  },
  updateEmoney(data) {
    return request({
      url: "/user/updateEmoneyPasspost",
      base: site,
      data,
      method: "post",
    });
  },
  setEmoney(data) {
    return request({
      url: "/user/setEmoneyPasspost ",
      base: site,
      data,
      method: "post",
    });
  },
  certification(data) {
    return request({
      url: "/user/certification",
      method: "post",
      data,
      base: site,
    });
  },
  editname(data) {
    return request({
      url: "/index/editname",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  picture(data) {
    return request({
      url: "/upload/picture",
      method: "post",
      // formData: true,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  send_edit_code(data) {
    return request({
      url: "/sem/send_edit_code",
      method: "post",
      data,
    });
  },
  saveUsdtAdderss(data) {
    return request({
      url: "/user/saveUsdtAdderss",
      method: "post",
      data,
      base: site,
    });
  },
  destroy() {
    return request({
      url: "/login/destroy",
      method: "get",
    });
  },
  teamwithdraw(params) {
    return request({
      url: "/agent/teamwithdraw",
      method: "get",
      params,
    });
  },
  teamrecharge(params) {
    return request({
      url: "/agent/teamrecharge",
      method: "get",
      params,
    });
  },
  team(params) {
    return request({
      url: "/agent/team",
      method: "get",
      params,
    });
  },
  first(params) {
    return request({
      url: "/agent/index",
      method: "get",
      params,
    });
  },
  teamdirect(params) {
    return request({
      url: "/agent/teamdirect",
      method: "get",
      params,
    });
  },
  teamRegister(params) {
    return request({
      url: "/agent/teamregister",
      method: "get",
      params,
    });
  },
  turnoverType() {
    return request({
      url: "/index/turnovertype",
      method: "get",
    });
  },
  turnover(params) {
    return request({
      url: "/wallet/turnover",
      method: "get",
      params,
    });
  },
  product_list(params) {
    return request({
      url: "/robot/product_list",
      method: "get",
      params,
    });
  },
  productdetail(params) {
    return request({
      url: "/robot/productdetail",
      method: "get",
      params,
    });
  },
  bannerlist() {
    return request({
      url: "/index/bannerlist",
      method: "get",
    });
  },
  announce() {
    return request({
      url: "/info/announce",
      method: "get",
    });
  },
  getRecommendationLink() {
    return request({
      url: "/user/getRecommendationLink",
      method: "get",
      base: site,
    });
  },
  withdraw(data) {
    return request({
      url: "/wallet/withdraw",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  withPostPwdCheck(data) {
    return request({
      url: "/user/withPostPwdCheck",
      method: "post",
      data,
      base: site,
    });
  },
  rechargeWithdrawLog() {
    return request({
      url: "/wallet/rechargeWithdrawLog",
      method: "get",
    });
  },
  refundRobot(data) {
    return request({
      url: "/Robot/refundRobot",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  aisle() {
    return request({
      url: "/payment/aisle",
      method: "get",
      base: site,
    });
  },
  addBalance(data) {
    return request({
      url: "/payment/addBalance",
      method: "post",
      base: site,
      data,
    });
  },
  convert(data) {
    return request({
      url: "/convert/handle",
      method: "post",
      base: site,
      data,
    });
  },
  getUserInfoByInviteCode(params) {
    return request({
      url: "/user/getUserInfoByInviteCode",
      method: "get",
      base: site,
      params,
    });
  },
  getInviteCodeServiceCharge(params) {
    return request({
      url: "/user/getInviteCodeServiceCharge",
      method: "get",
      base: site,
      params,
    });
  },
  transferByIntegralC(data) {
    return request({
      url: "/user/transferByIntegralC",
      method: "post",
      base: site,
      data,
    });
  },
  getBankInfo() {
    return request({
      url: "/bank/getBankInfo",
      method: "get",
      base: site,
    });
  },
  bankBuy(data) {
    return request({
      url: "/bank/buy",
      method: "post",
      base: site,
      data,
    });
  },
  prize() {
    return request({
      url: "/lucky/prize",
      method: "get",
      base: site,
    });
  },
  showPrize() {
    return request({
      url: "/lucky/prize/show",
      method: "get",
      base: site,
    });
  },
  roll() {
    return request({
      url: "/lucky/roll",
      method: "post",
      base: site,
    });
  },
  myPrize() {
    return request({
      url: "lucky/my/prize",
      method: "get",
      base: site,
    });
  },
  receive(data) {
    return request({
      url: "lucky/receive",
      method: "post",
      base: site,
      data,
    });
  },
  tuijian_list(params) {
    return request({
      url: "good_indent/tuijian_list",
      method: "get",
      params,
    });
  },
  AllAddr() {
    return request({
      url: "member_addr/AllAddr",
      method: "get",
    });
  },
  member_addr_create(data) {
    return request({
      url: "member_addr/create",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  member_addr_edit(data) {
    return request({
      url: "member_addr/edit",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  goodDetail(params) {
    return request({
      url: "good_indent/goodDetail",
      method: "get",
      params,
    });
  },
  good_indent_buy(data) {
    return request({
      url: "good_indent/buy",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  goodCateList() {
    return request({
      url: "good_indent/goodCateList",
      method: "get",
    });
  },
  good_indent_product_list() {
    return request({
      url: "good_indent/product_list",
      method: "get",
    });
  },
};
