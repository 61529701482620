<template>
  <div class="home">
    <p class="logo center-center">
      <img class="no-grow no-shrink" src="@/assets/img/logo.png" />
    </p>
    <!-- <div class="center-center">
      <img class="no-grow no-shrink" src="@/assets/img/focus.png" />
    </div> -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="item in banner"
        :key="item.id"
        class="my-swipe-item"
      >
        <VImage :src="item.path" class="swipe-img" />
      </van-swipe-item>
    </van-swipe>
    <ul
      @click="$router.push('/announcementDetail/' + notice.id)"
      class="voice-list space-between align-center"
    >
      <li class="voice no-grow no-shrink">
        <img src="@/assets/img/voice.png" />
      </li>
      <li class="flex-1 cont ellipsis">
        <van-swipe
          class="my-swipe"
          style="height: 36px"
          :autoplay="3000"
          vertical
          :show-indicators="false"
        >
          <van-swipe-item v-for="item in notice" :key="item.id" class="title">{{
            item.title
          }}</van-swipe-item>
        </van-swipe>
      </li>
      <li class="doble no-grow no-shrink">
        <img src="@/assets/img/lefticon.png" />
      </li>
    </ul>
    <ul
      @click="$router.push('/shop')"
      class="out-shop space-between align-center"
    >
      <li class="align-center text">
        <img src="@/assets/img/car.png" class="no-grow no-shrink" />
        海外购
      </li>
      <li class="skip align-center">
        更多<img src="@/assets/img/doble.png" />
      </li>
    </ul>
    <div class="d-flex flex-wrap">
      <div
        @click="$router.push({ name: 'ShopDetail', params: { id: item.id } })"
        class="goods-item"
        v-for="item in list"
        :key="item.id"
      >
        <ul class="goods">
          <li class="pic">
            <img :src="item.thumb" />
          </li>
          <li class="name">{{ item.title }}</li>
          <li class="price">${{ item.ds_price }}</li>
          <li class="align-center space-between">
            <span class="org-price">原价${{ item.price }}</span
            ><span class="sold">已售{{ item.sales }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import apiUser from "@/api/user";
export default {
  name: "HomeView",
  data() {
    return {
      notice: [],
      banner: [],
      list: [
        // {
        //   img: "7",
        //   name: "加载中...",
        //   price: "0",
        //   orgPrice: "0",
        //   sold: "0",
        //   id: "-1",
        // },
      ],
    };
  },
  computed: {
    // notice: function () {
    //   if (window.navigator.userAgent.indexOf("ios") !== -1) {
    //     return this.$store.state.user.config.ios_notice;
    //   } else {
    //     return this.$store.state.user.config.android_notice;
    //   }
    // },
  },
  async created() {
    this.$store.commit("CHANGE_SHOW_BAR", true);
    apiUser.bannerlist().then(([err, res]) => {
      if (err) return;
      // if (res.data) {
      //   res.data.map((e) => {
      //     e.path = "https://admin.cdbreg.vip" + e.path;
      //   });
      // }
      this.banner = res.data || [];
    });
    apiUser.announce().then(([err, res]) => {
      if (err) return;
      this.notice = res.data;
    });
    apiUser
      .tuijian_list({
        is_home: 1,
        tid: "",
        title: "",
        price_min: "",
        price_max: "",
        idsort: 1,
        salessort: 1,
        pricesort: 1,
      })
      .then(([err, res]) => {
        if (err) return;
        res.data.map((e, i) => {
          res.data[i].thumb = this.VUE_APP_API + e.thumb;
        });
        this.list = res.data;
      });
  },
  async mounted() {
    new Swiper(".swiper-container", {
      autoplay: true,
      loop: true,
    });
  },
};
</script>
<style lang="less" scoped>
.home {
  background: no-repeat center top url("@/assets/img/top-bg.png");
  background-size: 100% auto;
  .title {
    line-height: 36px;
  }
  .swipe-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .logo {
    height: 60px;
    img {
      height: 40px;
    }
  }
  .voice-list {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    height: 36px;
    margin: 15px 0;
    padding: 0 10px;
    .cont {
      padding: 0 4px;
      text-align: left;
    }
    img {
      display: block;
    }
    .doble {
      img {
        height: 12px;
      }
    }
    .voice {
      img {
        height: 18px;
      }
    }
  }
  .out-shop {
    padding: 0 20px;
    color: @color-primary;
    height: 50px;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(254, 139, 41, 0.2) 0%,
      rgba(254, 139, 41, 0) 100%
    );
    .skip {
      font-size: 12px;
      img {
        margin-left: 8px;
        height: 12px;
        display: block;
      }
    }
    .text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      font-weight: 400;
      img {
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .goods-item {
    padding: 0 5px 10px;
    width: 50%;
    color: #999;
    .goods {
      padding: 10px 10px 10px;
      border-radius: 12px;
      background: #fff;
    }
    .pic {
      height: 128px;
      margin-bottom: 15px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .name {
      color: #333;
      font-size: 15px;
      line-height: 26px; /* 173.333% */
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .price {
      color: #fe8b29;
      font-size: 16px;
      font-weight: 600;
    }
    .org-price {
      font-size: 12px;
      text-decoration: line-through;
    }
    .sold {
      font-size: 12px;
    }
  }
  .my-swipe {
    height: 170px;
  }
  .my-swipe-item {
    height: 170px;
  }
}
</style>
