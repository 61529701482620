<template>
  <div class="login-layout">
    <p class="logo">
      <img class="d-block" src="@/assets/img/logo.png" />
    </p>
    <p class="title">{{ title }}</p>
    <div class="login-form">
      <slot name="form"> </slot>
    </div>
    <div v-if="showAgreen" class="agreen align-center">
      <van-checkbox v-model="checked">我已阅读并同意</van-checkbox>
      <span class="agreen-text">《用户协议》</span>
      和
      <span class="agreen-text">《隐私政策》</span>
    </div>
    <div class="login-form-btm">
      <slot name="btm"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginLayout",
  props: {
    title: {
      type: String,
      default: "欢迎登录",
    },
    showAgreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    checked(val) {
      this.$emit("changeChecked", val);
    },
  },
};
</script>
<style lang="less" scoped>
.login-layout {
  background: linear-gradient(
    180deg,
    rgba(254, 139, 41, 0.3) 0%,
    rgba(254, 139, 41, 0) 100%
  );
  padding: 60px 20px 0;
  .logo {
    margin-bottom: 30px;
    img {
      width: 170px;
    }
  }
  .title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  /deep/ .agreen {
    .van-checkbox__icon--checked .van-icon {
      background-color: @color-primary;
      border-color: @color-primary;
    }
  }
  /deep/ .login-form {
    .van-cell {
      background: none;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }
    .van-field__body {
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .agreen {
    margin-top: 60px;
    .agreen-text {
      color: #01a8f1;
    }
  }
}
</style>
