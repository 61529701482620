import {
  Button,
  Toast,
  Form,
  Field,
  Checkbox,
  Swipe,
  SwipeItem,
  Icon,
  Image as VanImage,
  ActionSheet,
  DatetimePicker,
  PasswordInput,
  NumberKeyboard,
  Popup,
  Picker,
  Area,
  Dialog,
  Search,
  PullRefresh,
  List,
  Cell,
  Col,
  Row,
  Tab,
  Tabs,
  Stepper,
  Divider,
  CellGroup,
  Radio,
  RadioGroup,
} from "vant";
export default {
  install(Vue) {
    Vue.use(RadioGroup);
    Vue.use(Radio);
    Vue.use(CellGroup);
    Vue.use(Divider);
    Vue.use(Stepper);
    Vue.use(Tab);
    Vue.use(Tabs);
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Cell);
    Vue.use(List);
    Vue.use(PullRefresh);
    Vue.use(Search);
    Vue.use(Button);
    Vue.use(Dialog);
    Vue.use(Toast);
    Vue.use(Form);
    Vue.use(Field);
    Vue.use(Checkbox);
    Vue.use(Swipe);
    Vue.use(SwipeItem);
    Vue.use(Icon);
    Vue.use(VanImage);
    Vue.use(ActionSheet);
    Vue.use(DatetimePicker);
    Vue.use(PasswordInput);
    Vue.use(NumberKeyboard);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Area);
  },
};
